import { useEffect, useState } from "react";
import { getFormattedDate, get24Time } from "../../utils/date";

import { ContentHeader } from "../Container.Style";
import { Details } from "./Conference.Style";
import Button from "../Input/Button";
import Modal from "../Modal/Modal";
import ModalInput from "../Modal/ModalInput";
import { ModalForm } from "../Modal/Modal.Style";

import SpeakerIcon from "../../assets/images/speaker.svg";
import LocationIcon from "../../assets/images/location.svg";
import CalendarIcon from "../../assets/images/calender.svg";
import TimeIcon from "../../assets/images/time.svg";
import MicrophoneActiveIcon from "../../assets/images/microphone-active.svg";
import ExportIcon from "../../assets/images/export.svg";

import UserSample from "../../samples/user.xlsx";
import TeamSample from "../../samples/team.xlsx";
import ExhibitSample from "../../samples/exhibit.xlsx";
import AgendaSample from "../../samples/agenda.xlsx";
import SponsorSample from "../../samples/sponsor.xlsx";

import conferenceServices from "../../services/conference.services";

function SampleList(props) {
  return (
    <li
      style={{
        display: "flex",
        margin: "10px 0",
        justifyContent: "space-between",
      }}
    >
      <span>{props.name}</span>
      <a
        style={{ textDecoration: "none", color: "#f7ad00" }}
        download={props.filename}
        href={props.file}
      >
        {props.filename}
      </a>
    </li>
  );
}

function ConferenceInfo({ conferenceId, setConference, conference }) {
  const [modal, setModal] = useState(false);
  const [samples, setSamples] = useState(false);
  const [newConference, setNewConference] = useState({
    hostname: "",
    name: "",
    address: "",
    website: "",
    liveOn: null,
    endOn: null,
    company_chat: false,
    team_module: false,
    set: false,
  });
  const [status, setStatus] = useState({});

  function updateStatus(liveOn, closeOn) {
    const start = liveOn?.date && new Date(liveOn.date);
    const end = liveOn?.date && new Date(closeOn.date);
    const status = {};

    if (!start || !end) {
      status.id = "Not-started";
      status.label = "-";
      status.time = `-`;
      setStatus({ ...status });
      return;
    }

    if (start.getTime() > Date.now()) {
      status.id = "not-started";
      status.label = "Yet To Begin";
      status.time = `Live At ${get24Time(liveOn.time)}`;
      setStatus({ ...status });
      return;
    }

    if (start.getTime() < Date.now() && Date.now() < end.getTime()) {
      status.id = "open";
      status.label = "Live";
      status.time = `End At ${get24Time(closeOn.time)}`;
      setStatus({ ...status });
      return;
    }

    if (end.getTime() < Date.now()) {
      status.id = "closed";
      status.label = "Ended";
      status.time = `Ended At ${get24Time(closeOn.time)}`;
      setStatus({ ...status });
      return;
    }
  }

  const handleInput = (event) => {
    const { name, value } = event.target;
    newConference[name] = value;
    setNewConference({ ...newConference });
  };

  const handleTimeInput = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "startDate":
        const date = new Date(`${value}T00:00`);
        if (newConference.liveOn) newConference.liveOn.date = date;
        else {
          newConference.liveOn = { date };
        }
        break;
      case "startTime":
        const startTime = event.target.valueAsNumber;
        if (newConference.liveOn) newConference.liveOn.time = startTime;
        else {
          newConference.liveOn = { time: startTime };
        }
        break;
      case "endDate":
        const endDate = new Date(`${value}T00:00`);
        if (newConference.endOn) newConference.endOn.date = endDate;
        else {
          newConference.endOn = { date: endDate };
        }
        break;
      case "endTime":
        const endTime = event.target.valueAsNumber;
        if (newConference.endOn) newConference.endOn.time = endTime;
        else {
          newConference.endOn = { time: endTime };
        }
        break;
    }
    setNewConference({ ...newConference });
  };

  const updateConference = () => {
    console.log("newConference", newConference);
    conferenceServices
      .updateConference(conference._id, newConference)
      .then(() => {
        setConference({ ...conference, ...newConference });
        setModal(false);
      })
      .catch((err) => alert(err.message));
  };

  useEffect(() => {
    conferenceServices
      .getConference(conferenceId)
      .then((response) => {
        setConference({
          ...response.data.conference,
        });
      })
      .catch((err) => alert(err.message));
  }, [conferenceId, setConference]);

  useEffect(() => {
    if (!conference?._id) return;
    updateStatus(conference.liveOn, conference.endOn);
  }, [conference]);

  useEffect(() => {
    // console.log("conference", conference);
    if (!conference?._id) return;
    setNewConference(
      modal
        ? {
            hostname: conference.hostname,
            name: conference.name,
            address: conference.address,
            website: conference.website,
            liveOn: conference.liveOn,
            endOn: conference.endOn,
            company_chat: conference.company_chat,
            team_module: conference.team_module,
            set: true,
          }
        : {}
    );
  }, [modal, conference]);

  return (
    <>
      <ContentHeader>
        <div className="head-block">
          <h2>{conference.name}</h2>
        </div>
        <div className="head-block right">
          <Button
            icon={ExportIcon}
            onClick={() => setSamples(true)}
            btnType={`${false ? "disabled" : ""} exportBtn`}
            name="Import Samples"
          />
          <Button
            onClick={() => setModal(true)}
            btnType={`${false ? "disabled" : ""} conferenceBtn`}
            name="Edit Conference"
          />
        </div>
      </ContentHeader>
      <Details>
        <li>
          <img src={SpeakerIcon} alt="speaker" />
          <span>{conference?.hostname || ""}</span>
        </li>
        <li>
          <img src={CalendarIcon} alt="calendar" />
          <span>
            {`${
              conference?.liveOn?.date &&
              getFormattedDate(conference?.liveOn?.date, "/", "MM/DD/YY")
            } - ${
              conference?.endOn?.date &&
              getFormattedDate(conference?.endOn?.date, "/", "MM/DD/YY")
            }`}
          </span>
        </li>
        <li>
          <img src={LocationIcon} alt="location" />
          <span>{conference?.address}</span>
        </li>
        <li>
          <img src={TimeIcon} alt="time" />
          <span>
            <label className={`status ${status?.id}`}>{status?.label}</label>
            <label className="dot"></label>
            <label>{status.time}</label>
          </span>
        </li>
      </Details>
      {modal && newConference.set && (
        <Modal
          width={"50%"}
          icon={MicrophoneActiveIcon}
          heading={"Edit Conference"}
          closeModal={() => setModal(!modal)}
        >
          <ModalForm>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "48.5% 48.5%",
                gridGap: "3%",
              }}
            >
              <ModalInput
                name="name"
                label={"Name Of Conference"}
                value={newConference?.name || ""}
                placeholder={"Conference Name"}
                onChange={handleInput}
              />
              <ModalInput
                name="hostname"
                label={"Host Name"}
                value={newConference?.hostname || ""}
                placeholder={"Host Name"}
                onChange={handleInput}
              />
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "48.5% 48.5%",
                gridGap: "3%",
              }}
            >
              <ModalInput
                name="startDate"
                label={"Start Date"}
                type="date"
                value={
                  newConference.liveOn?.date
                    ? getFormattedDate(
                        new Date(newConference.liveOn.date),
                        "-",
                        "YY-MM-DD"
                      )
                    : ""
                }
                placeholder={"Conference Date"}
                onChange={handleTimeInput}
              />
              <ModalInput
                name="startTime"
                label={"Start Time"}
                type="time"
                value={
                  newConference.liveOn?.time
                    ? get24Time(newConference.liveOn.time)
                    : ""
                }
                readOnly={newConference.liveOn?.date ? false : true}
                placeholder={"Conference Start Time"}
                onChange={handleTimeInput}
              />
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "48.5% 48.5%",
                gridGap: "3%",
              }}
            >
              <ModalInput
                name="endDate"
                label={"End Date"}
                type="date"
                value={
                  newConference.endOn?.date
                    ? getFormattedDate(
                        new Date(newConference.endOn.date),
                        "-",
                        "YY-MM-DD"
                      )
                    : ""
                }
                placeholder={"Conference End Date"}
                onChange={handleTimeInput}
              />
              <ModalInput
                name="endTime"
                label={"End Time"}
                type="time"
                value={
                  newConference.endOn?.time
                    ? get24Time(newConference.endOn.time)
                    : ""
                }
                readOnly={newConference.endOn?.date ? false : true}
                placeholder={"Conference End Time"}
                onChange={handleTimeInput}
              />
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "48.5% 48.5%",
                gridGap: "3%",
              }}
            >
              <ModalInput
                name="address"
                label={"Address"}
                value={newConference.address || ""}
                placeholder={"Conference Address"}
                onChange={handleInput}
              />
              <ModalInput
                name="website"
                label={"Website"}
                value={newConference.website}
                placeholder={"Conference Website"}
                onChange={handleInput}
              />
            </div>
            <div
              style={{
                // backgroundColor: "red",
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "20px",
              }}
            >
              <div style={{ marginLeft: "0px" }}>
                <div
                  style={{
                    marginBottom: "10px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Company Chat
                  {/* {newConference.company_chat ? "1" : "0"} */}
                </div>
                <ToggleButton
                  onChange={(value) => {
                    // newConference["company_chat"] = value;
                    newConference["company_chat"] = value;
                    setNewConference({ ...newConference });
                  }}
                  value={newConference.company_chat ? true : false}
                />
              </div>
              <div style={{ marginLeft: "0px" }}>
                <div
                  style={{
                    marginBottom: "10px",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Team Module
                  {/* {newConference.team_module ? "1" : "0"} */}
                </div>
                <ToggleButton
                  onChange={(value) => {
                    // newConference["team_module"] = value;
                    newConference["team_module"] = value;
                    setNewConference({ ...newConference });
                  }}
                  value={newConference.team_module ? true : false}
                />
              </div>
            </div>
            <Button
              btnType={`${false ? "disabled" : ""} modalBtn`}
              name="EDIT"
              onClick={() => updateConference()}
            />
          </ModalForm>
        </Modal>
      )}
      {samples && (
        <Modal
          icon={null}
          heading={"Download Import Samples"}
          closeModal={() => setSamples(!samples)}
        >
          <ul style={{ listStyle: "none", padding: 0 }}>
            <SampleList
              name="1. User Import Sample"
              file={UserSample}
              filename={"user.xlsx"}
            />
            <SampleList
              name="2. Team Import Sample"
              file={TeamSample}
              filename={"team.xlsx"}
            />
            <SampleList
              name="3. Agenda Import Sample"
              file={AgendaSample}
              filename={"agenda.xlsx"}
            />
            <SampleList
              name="4. Sponsors Import Sample"
              file={SponsorSample}
              filename={"sponsor.xlsx"}
            />
            <SampleList
              name="5. Resources Import Sample"
              file={SponsorSample}
              filename={"resources.xlsx"}
            />
            {/* <SampleList
              name="5. Exhibits Import Sample"
              file={ExhibitSample}
              filename={"exhibit.xlsx"}
            /> */}
          </ul>
        </Modal>
      )}
    </>
  );
}

const ToggleButton = ({ value, onChange }) => {
  const [buttonState, setButtonState] = useState(false);

  useEffect(() => {
    setButtonState(value);
  }, []);

  return (
    <label className="sfasfS_switch">
      <input
        type="checkbox"
        checked={buttonState}
        onClick={() => {
          onChange(!buttonState);
          setButtonState(!buttonState);
        }}
      />
      <span className="sfasfS_slider round"></span>
    </label>
  );
};

export default ConferenceInfo;
